<template>
  <div>
    <b-modal
      id="modal-add-file"
      :visible="show"
      title="Thêm file"
      centered
      hide-footer
      @hidden="resetModal"
    >
      <b-overlay :show="loading">
        <form @submit.prevent="handleValidate">
          <basic-input
            :value.sync="form.name"
            name="name"
            label="Tên báo cáo"
            placeholder="Nhập tên báo cáo"
            required
            v-validate="'required|max:100'"
            :state="validateState('name')"
            :invalidFeedback="errors.first('name')"
            data-vv-as="Tên báo cáo"
            maxlength="100"
          />
          <div class="mb-7">
            <div class="wrap__file">
              <span class="file__name">
                {{ file ? fileName : 'file import.pdf' }}
              </span>
              <b-icon-upload
                scale="1.5"
                class="cursor-pointer"
                @click="$refs.fileUpload.click()"
              ></b-icon-upload>
              <input
                v-show="false"
                type="file"
                name=""
                id="file-upload"
                ref="fileUpload"
                accept=".pdf"
                @change="onUploadFile"
              />
            </div>
            <div class="error text-danger">
              <p v-if="showFileRequire">File là bắt buộc</p>
              <p v-if="isNotPdfFile" class="mb-1">Định dạng ảnh phải là PDF</p>
              <span v-if="showFileSizeError"
                >Kích thước file import tối đa nhỏ hơn 1MB</span
              >
            </div>
          </div>
          <basic-input
            :value.sync="form.note"
            name="note"
            label="Ghi chú"
            placeholder="Nhập ghi chú"
            maxlength="200"
            v-validate="'max:200'"
          />
          <div class="d-flex justify-content-end align-items-center mt-4">
            <b-button class="btn mr-2" type="button" @click="resetModal">
              Hủy
            </b-button>
            <b-button class="btn btn-success ml-3" type="submit">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Lưu
            </b-button>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { PDF_FILE_TYPE } from '@/core/plugins/constants';
export default {
  name: 'ModalAddFile',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFileSizeError: false,
      isNotPdfFile: false,
      showFileRequire: false,
      file: null,
      form: {
        name: null,
        note: null,
      },
      loading: false,
    };
  },
  computed: {
    fileName() {
      return this.file && this.file.name;
    },
    disableBtnImport() {
      return !this.file || this.showFileSizeError;
    },
  },
  methods: {
    resetModal() {
      this.$emit('reset');
    },
    handleValidate() {
      this.showFileRequire = this.file ? false : true;
      this.$validator.validateAll().then((result) => {
        if (
          !result ||
          this.showFileRequire ||
          this.showFileSizeError ||
          this.isNotPdfFile
        )
          return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const payload = {
          reportName: this.form.name,
          description: this.form.note,
          patientId: this.$route.params.id,
        };

        payload['virtualFilePath'] = await this.uploadFile();

        const { error } = await this.$api.post(
          '/UserDashboard/Patient-Report',
          payload,
        );

        if (error) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.$emit('reload-data');
        this.resetModal();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async uploadFile() {
      try {
        const { error, data } = await this.$store.dispatch(
          'context/UPLOAD_FILE',
          this.file,
        );
        if (error) {
          return this.showToastrMessage(error.message, 'error');
        }
        return data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
        return null;
      }
    },
    onUploadFile(event) {
      const file = event.target.files[0];
      this.showFileRequire = false;
      // validate file type
      if (!PDF_FILE_TYPE.includes(file.type)) {
        this.isNotPdfFile = true;
        return (this.$refs.fileUpload.value = null);
      }

      if (file.size > 1024000 * 5) {
        return (this.showFileSizeError = true);
      }

      this.file = file;
      this.showFileSizeError = false;
      this.isNotPdfFile = false;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap__file {
  padding: 10px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap__icon {
  background: #e4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
